import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "custom-url": _vm.customUrl,
      "custom-query": _vm.customQuery,
      "hide-pagination": true,
      "title": "Reports",
      "has-active": false,
      "has-filter": false,
      "has-filter-inactive": false,
      "filters": {
        key: "name",
        items: _vm.excludedReports
      }
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var record = _ref.record;
        return [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Preview")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "btn-bunker",
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.preview(record.name);
            }
          }
        })], 1)];
      }
    }])
  }, [_c("text-field", {
    key: "ReportName",
    attrs: {
      "data-index": "name",
      "title": "Report Name",
      "fixed": "left",
      "width": 200
    }
  }), _c("text-field", {
    key: "Description",
    attrs: {
      "data-index": "description",
      "title": "Description",
      "width": 360
    }
  })], 1);
};
var staticRenderFns$1 = [];
const excludedReports = [
  "AuditNegativeBins",
  "BinBalances",
  "BinTransactions",
  "ContractList",
  "Extract1",
  "GradingCertificateEmail",
  "GrowerPaddockEmail",
  "HarvestDocket",
  "HarvestDocketObservation",
  "IntakeInquiryGradingCert",
  "IntakeInquiryMultiWeight",
  "IntakeInquirySamples",
  "VBinDocket"
];
const __vue2_script$1 = {
  name: "ListLabel",
  inject: ["customUrl", "customQuery", "apiUrl"],
  data() {
    return {
      excludedReports,
      record: null
    };
  },
  methods: {
    preview(type) {
      const data = {
        report: type,
        reportType: "grower",
        reportParams: {}
      };
      this.axios.post(this.apiUrl + `/identities/reports`, data).then((res) => {
        if (!res || !res.data || !res.data.reportViewerUri) {
          this.$notification["error"]({
            message: "Report not found!"
          });
        } else {
          window.open(res.data.reportViewerUri, "_blank");
        }
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Errors!"
        });
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReportsMenu = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "reports-menu"
  }, [_c("resource", {
    attrs: {
      "name": "grower.reports",
      "api-url": _vm.apiUrl
    }
  }, [_c("reports-menu")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ReportsMenu
  },
  provide() {
    return {
      customUrl: `${this.apiUrl}/identities/reports`,
      customQuery: "app=grower",
      apiUrl: this.apiUrl
    };
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
